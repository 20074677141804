<template>
  <div class="suggestion">
    <div class="title">
      <router-link :to="route"> {{ title }}</router-link>
      <span v-if="comments">
        <i class="el-icon-chat-round" /> {{ comments }}
      </span>
    </div>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "SuggestionTitle",
  props: {
    id: {
      type: [String, Number],
      default: "",
      required: true,
    },
    title: {
      type: String,
      default: "",
      required: true,
    },
    description: {
      type: String,
      default: "",
      required: true,
    },
    comments: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    route() {
      return {
        name: "feedback.suggestions.suggestion",
        params: { id: this.id },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.suggestion {
  position: relative;

  .title {
    span {
      margin-left: 10px;
    }
  }
  a {
    color: var(--eden-green-primary);
    font-size: 0.825rem;
  }

  p {
    color: var(--eden-grey-secondary);
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 8px;
    font-size: 0.825rem;
  }

  .item {
    display: inline-block;
    margin-top: 8px;
    color: var(--eden-grey-primary);
    border: 1px solid var(--eden-grey-quaternary);
    border-radius: 8px;
    padding: 4px 8px;
    font-size: 0.75rem;
  }
}
</style>
