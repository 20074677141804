<template>
  <eden-filter
    :width="270"
    :properties.sync="properties"
    :disabled="disabled"
    :loading="loading"
    @open="getData"
    @reset="reset"
    @filter="filter"
  />
</template>

<script>
import { setDepartments } from "../feedback.config";

export default {
  name: "SuggestionsFilter",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      properties: {
        status: {
          key: "status",
          label: "Status",
          type: "list",
          options: [
            {
              label: "Pending",
              value: "pending",
            },
            {
              label: "Being considered",
              value: "being-considered",
            },
            {
              label: "Implemented",
              value: "implemented",
            },
            {
              label: "Not accepted",
              value: "not-accepted",
            },
            {
              label: "Already exists",
              value: "already-exists",
            },
          ],
          value: [],
        },
        department: {
          key: "department",
          label: "Department",
          type: "list",
          options: [],
          value: [],
          searchable: true,
        },
        date: {
          key: ["date_start_date", "date_end_date"],
          label: "Date Created",
          type: "daterange",
          value: [],
        },
      },
    };
  },
  watch: {
    clear() {
      if (this.clear) {
        this.reset();
      }
    },
  },
  methods: {
    getData() {
      this.setDepartmentOptions();
    },
    setDepartmentOptions() {
      this.properties.department.options = setDepartments().map(
        (department) => {
          return {
            label: department.name,
            value: department.id,
          };
        },
      );
      this.loading = false;
    },
    reset() {
      Object.keys(this.properties).forEach((key) => {
        this.properties[key].value = [];
      });
    },
    filter() {
      let params = {
        params: {},
        paramsLabel: {},
      };

      Object.keys(this.properties).forEach((property) => {
        const { key, type, value } = this.properties[property];
        if (value && value.length) {
          let useValue = value[0];
          switch (type) {
            case "list":
              if (key === "department") {
                useValue = setDepartments().find(
                  (department) => department.id === useValue,
                ).name;
              }
              params.params[key] = useValue;
              params.paramsLabel[key] = this.properties[property];
              break;
            case "list-multiple":
              params.params[key] = value;
              params.paramsLabel[key] = this.properties[property];
              break;
            case "daterange":
              params.params[key[0]] = this.properties[property].value[0];
              params.params[key[1]] = this.properties[property].value[1];
              params.paramsLabel[key] = this.properties[property];
              break;
            default:
              break;
          }
        }
      });

      if (Object.keys(params.params).length) {
        this.$emit("filter", {
          params: params.params,
          paramsLabel: params.paramsLabel,
        });
      } else {
        this.$message.info("Filtering requires at least one property");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
