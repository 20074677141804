<template>
  <div class="home">
    <eden-page-header :title="'Suggestions Log'">
      <template slot="actions">
        <div class="page--head-actions">
          <el-button
            type="primary"
            v-if="
              allowAccessFor([
                'superadmin',
                'admin',
                'operations',
                'kitchen',
                'growth',
                'gardener',
                'gardener_pro',
              ])
            "
            @click="showSuggestionForm = true"
          >
            Add Suggestion
          </el-button>
        </div>
      </template>
    </eden-page-header>
    <eden-page-stats :title="'Overview'" :stats="stats" />
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <suggestions-filter
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
      </template>
    </eden-table-actions>

    <template>
      <eden-loader v-if="loading" />
      <template v-else>
        <eden-filter-items
          v-if="filterParams.status"
          :params="filterParams.paramsLabel"
          @clear-filter="clearFilter"
          @clear-filters="clearFilters"
        />
        <template v-if="suggestionLogs.length">
          <el-table :data="suggestionLogs">
            <el-table-column width="350">
              <template slot="header">
                <span>Suggestion</span>
              </template>
              <template v-slot="scope">
                <suggestion-title
                  :id="scope.row.id"
                  :title="scope.row.title"
                  :description="scope.row.description"
                  :comments="scope.row.comments.length"
                />
              </template>
            </el-table-column>
            <el-table-column width="160">
              <template slot="header">
                <eden-table-column-header
                  :label="'Status'"
                  :property="'status'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'status')"
                />
              </template>
              <template v-slot="scope">
                <el-tag :type="setType(scope.row.status)">{{
                  formatText(scope.row.status.replace("-", " "))
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <eden-table-column-header
                  :label="'Department'"
                  :property="'department'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'department')"
                />
              </template>
              <template v-slot="scope">
                <span class="font-sm"> {{ scope.row.department }}</span>
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <span class="font-sm"> Originator </span>
              </template>
              <template v-slot="scope">
                <router-link
                  v-if="scope.row.customer_type === 'customer'"
                  class="text-primary"
                  :to="{
                    name: 'customers.individual',
                    params: { id: scope.row.suggester.id },
                  }"
                >
                  {{ scope.row.suggester.name }}</router-link
                >
                <p v-else>{{ scope.row.suggester.name }}</p>
                <span class="is-block">{{
                  formatText(scope.row.customer_type)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column width="180">
              <template slot="header">
                <span class="font-sm"> Date </span>
              </template>
              <template v-slot="scope">
                <span class="font-sm">
                  {{ formatDate(scope.row.created_at, "m do, y") }}</span
                >
              </template>
            </el-table-column>
          </el-table>
          <eden-pagination
            v-if="showPagination"
            :from="from"
            :to="to"
            :total="total"
            :current-page.sync="page"
          />
        </template>
        <eden-content-empty v-else :text="'No data'" />
      </template>
    </template>
    <suggestion-form
      :show.sync="showSuggestionForm"
      @success="getSuggestionLogs"
    />
  </div>
</template>

<script>
import SuggestionTitle from "@/components/Feedback/Suggestions/Suggestion/Structure/SuggestionTitle";
import SuggestionForm from "@/components/Feedback/Suggestions/SuggestionForm";
import SuggestionsFilter from "@/components/Feedback/Suggestions/SuggestionsFilter";

import { formatSuggestions } from "@/components/Feedback/Suggestions/suggestions.config";
import suggestions from "../../../requests/feedback/suggestions";
import { setDepartments } from "../../../components/Feedback/feedback.config";

export default {
  name: "SuggestionsLog",
  components: {
    SuggestionForm,
    SuggestionTitle,
    SuggestionsFilter,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: formatSuggestions(),
      suggestionLogs: [],
      departmentFilter: "",
      departments: setDepartments(),
      showPagination: true,
      showSuggestionForm: false,
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      pagination: {
        from: null,
        to: null,
        total: null,
      },
      sort: {
        property: "name",
        direction: "asc",
      },
    };
  },
  computed: {
    suggestions() {
      return formatSuggestions();
    },
    from() {
      return 1;
    },
    to() {
      return this.suggestionLogs.length;
    },
    total() {
      return this.suggestionLogs.length;
    },
    title() {
      return `${this.total} Suggestions`;
    },
    stats() {
      const total = this.suggestionLogs.length;
      const implemented = this.suggestionLogs.filter(
        (suggestion) => suggestion.status === "implemented",
      );
      const pending = total - implemented.length;
      return [
        {
          label: "Total suggestions",
          figure: total,
        },
        {
          label: "Pending suggestions",
          figure: pending,
        },
        {
          label: "Implemented suggestions",
          figure: implemented.length,
        },
      ];
    },
  },
  watch: {
    page() {
      this.getSuggestionLogs();
    },
  },
  created() {
    this.initialiseFilters();
  },
  methods: {
    add() {
      this.showSuggestionForm = true;
    },
    search() {
      //
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.pageData = this.sortList(this.pageData, property, direction);
    },
    filter({ params, paramsLabel }) {
      const stringedParams = this.sortObjectAsParams(params) + "&";
      this.filterParams = {
        status: true,
        params: params,
        paramsLabel: paramsLabel,
      };
      this.getSuggestionLogs(stringedParams);
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;
      if (Object.keys(keys).length > 1) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.getSuggestionLogs();
    },
    getSuggestionLogs(params = "") {
      this.loading = true;
      const paramsPage = `page=${this.page}&` + `${params}`;
      suggestions
        .index(paramsPage)
        .then((response) => {
          this.loading = false;
          const { status, meta, data } = response.data;
          if (status) {
            this.suggestionLogs = data;
            this.pagination.from = meta.from;
            this.pagination.to = meta.to;
            this.pagination.total = meta.total;
            this.pagination.pageSize = meta.per_page;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },
    initialiseFilters() {
      const userRole = localStorage.getItem("eden-crm-user-role");
      const filterParams = {
        params: {},
        paramsLabel: {},
      };

      switch (userRole) {
        case "superadmin":
          this.departmentFilter = "Engineering";
          break;
        case "ofa":
          this.departmentFilter = "OFA";
          break;
        case "steward":
          this.departmentFilter = "Food Production Team";
          break;
        default:
          break;
      }

      if (this.departmentFilter) {
        filterParams.params = {
          ...filterParams.params,
          department: this.departmentFilter,
        };
        filterParams.paramsLabel = {
          ...filterParams.params,
          department: {
            key: "department",
            label: "Department",
            type: "list",
            options: [],
            value: [this.departmentFilter],
            searchable: true,
          },
        };
      }

      this.filter(filterParams);
      console.log(localStorage.getItem("eden-crm-user-role"));
    },
  },
};
</script>
