export const formatSuggestions = () => {
  return [
    {
      id: 1,
      title: "Tipping of Gardeners",
      description:
        "I believe that gardeners should be able to receive some more text to make this longer and whatnot whatnot.",
      attachments: [
        {
          id: 1,
          name: "Shawarma",
          url: "https://res.cloudinary.com/eden-life-inc/image/upload/v1659345790/lighthouse/mealImages/mn9epwgxp0akyjrfi1s6.jpg",
        },
        {
          id: 3,
          name: "Comparison",
          url: "https://res.cloudinary.com/eden-life-inc/image/upload/v1659345790/lighthouse/mealImages/mn9epwgxp0akyjrfi1s6.pdf",
        },
      ],
      department: {
        id: 1,
        name: "Product and Engineering",
      },
      status: "pending",
      originator: {
        type: "humaan",
        id: 24,
        name: "Raheem",
        date: "2021-07-02 14:42",
      },
      created_by: {
        name: "Raheem Roqeeb",
        date: "2021-07-02 14:42",
      },
      comments: [
        {
          id: 4,
          author: {
            id: 2482,
            name: "Firi Adoki",
          },
          created_at: "2022-08-10 14:59",
          comment:
            "At the moment, we’ve been able to fix attribution from all leads from our signup forms but we’re not " +
            "able to do the same for leads signing up to Lighthouse directly. As a result of this, we’ve initiated " +
            "the web signup plan, such that all our leads will be directed to sign up from the web app.",
        },
        {
          id: 1,
          author: {
            id: 2482,
            name: "Sola Ajayi",
          },
          created_at: "2022-04-01 14:59",
          comment: "The customer got their meal very late {{ @Firi Adoki }}",
        },
      ],
      resolvers: [
        {
          id: 1234,
          name: "Firi Adoki",
        },
        {
          id: 1234,
          name: "Aderinsola Oluwafemi",
        },
      ],
    },
    {
      id: 2,
      title: "Add Agege Bread to sides",
      description:
        "I believe that gardeners should be able to receive some more text to make this longer and whatnot whatnot.",
      attachments: [
        {
          id: 1,
          name: "Shawarma",
          url: "https://res.cloudinary.com/eden-life-inc/image/upload/v1659345790/lighthouse/mealImages/mn9epwgxp0akyjrfi1s6.jpg",
        },
        {
          id: 3,
          name: "Comparison",
          url: "https://res.cloudinary.com/eden-life-inc/image/upload/v1659345790/lighthouse/mealImages/mn9epwgxp0akyjrfi1s6.pdf",
        },
      ],
      department: {
        id: 1,
        name: "Product and Engineering",
      },
      status: "being_considered",
      originator: {
        id: 2343,
        type: "customer",
        name: "Adebayo Folorunsho",
        date: "2021-07-02 14:42",
      },
      created_by: {
        name: "Raheem Roqeeb",
        date: "2021-07-02 14:42",
      },
      comments: [
        {
          id: 4,
          author: {
            id: 2482,
            name: "Firi Adoki",
          },
          created_at: "2022-08-10 14:59",
          comment:
            "At the moment, we’ve been able to fix attribution from all leads from our signup forms but we’re not " +
            "able to do the same for leads signing up to Lighthouse directly. As a result of this, we’ve initiated " +
            "the web signup plan, such that all our leads will be directed to sign up from the web app.",
        },
      ],
      resolvers: [
        {
          id: 1234,
          name: "Aderinsola Oluwafemi",
        },
      ],
    },
    {
      id: 3,
      title: "Call in case of lateness",
      description:
        "Customer suggested that they should receive message or condition when it was delivered.",
      attachments: [],
      department: {
        id: 1,
        name: "Supply Ops",
      },
      status: "implemented",
      originator: {
        id: 2343,
        type: "customer",
        name: "Adebayo Folorunsho",
        date: "2021-07-02 14:42",
      },
      created_by: {
        name: "Raheem Roqeeb",
        date: "2021-07-02 14:42",
      },
      comments: [],
      resolvers: [
        {
          id: 1234,
          name: "Michael Oabsesan",
        },
      ],
    },
  ];
};
